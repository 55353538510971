import React from "react";

const NotFoundPage = () => {
return(
<>
<div style={{padding: '4rem 2rem', margin: '0 auto', textAlign: 'center'}}>
  <h1 style={{  fontSize: '150px',
  color: '#008B62',
  textShadow:'1px 1px 1px #00593E,2px 2px 1px #00593E,3px 3px 1px #00593E,4px 4px 1px #00593E,5px 5px 1px #00593E,6px 6px 1px #00593E,7px 7px 1px #00593E,8px 8px 1px #00593E,25px 25px 8px rgba(0,0,0, 0.2)'}}>404</h1>
  <div style={{ margin: '2rem 0',
  fontSize: '20px',
  fontWeight: '600',
  color: '#444'}}>Ooops!!! The page you are looking for is not found</div>
  <a style={{display: 'inline-block',
  border: '2px solid #222',
  color: '#ddd',
  textTransform: 'uppercase',
  fontWeight: '600',
  padding: '0.75rem 1rem 0.6rem',
  transition: 'all 0.2s linear',
  background: '#222',
  boxShadow: '0 3px 8px rgba(0,0,0, 0.3)'}} href="/">Back to home</a>
</div>
</>
);
}

export default NotFoundPage;