import React from "react";
import logo from "../assets/img/logo_color.svg";
import { getUsers } from "./Utils";

const HomeAR = () => {
  const [checked, setChecked] = React.useState(false);
  const dir = "rtl";
  const handleChange = () => {
    setChecked(!checked);
  };

  const handleVideoClick = () => {
    let users = getUsers();
    window.open(
      `https://teams.microsoft.com/l/call/0/0?users=${users}&withVideo=true`,
      "_blank"
    );
  };

  return (
    <div
      dir={dir}
      style={{
        fontFamily: "Frutiger,sans-serif",
        backgroundColor: "rgb(235, 243, 246)",
      }}
    >
      <nav
        class="navbar navbar-expand-md"
        style={{
          backgroundColor: "#efeff7",
          boxShadow: "0 0 30px 1.26px rgba(0,0,0,.26)",
          position: "fixed",
          width: "100%",
        }}
      >
        <div class="nav-item" style={{ marginLeft: "50px" }}>
          <div class="nav-link">
            <img
              src={logo}
              className="img-fluid me-4"
              alt="sign up"
              width="70"
              height="60"
            />
          </div>
        </div>
        <div style={{ display: "block", width: "inherit" }} id="navbarNav">
          <ul class="navbar-nav" style={{ fontSize: "20px", width: "inherit" }}>
            <li class="nav-item" style={{ width: "inherit" }}>
              <a
                style={{
                  color: "#134359",
                  float: "left",
                  marginRight: "40px",
                  marginLeft: "40px",
                }}
                class="nav-link"
                href="/en/dsb"
              >
                <i class="fa-solid fa-globe"></i> English
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div
        style={{
          height: "350px",
          fontWeight: "400",
          backgroundColor: "#ebf3f6",
          paddingTop: "150px",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            fontWeight: "400",
            marginBottom: "30px",
          }}
        >
          خدمة الاتصال المرئي للعملاء من قوى
        </h1>
      </div>
      <div
        class="container"
        style={{
          marginTop: "20px",
          paddingLeft: "30px",
          background: "white",
          paddingRight: "30px",
          paddingTop: "30px",
          border: "4px solid white",
          borderRadius: "5px",
        }}
      >
        <h1
          style={{
            fontWeight: "500",
          }}
        >
          خدمة رعاية العملاء (للأشخاص ذوي الاحتياجات الخاصة)
        </h1>
        <br></br>
        <br></br>
        <p
          style={{
            fontSize: "20px",
            lineHeight: "2",
            marginBottom: "30px",
          }}
        >
          في شركتنا ، نحن ملتزمون بتقديم خدمة رعاية العملاء من الدرجة الأولى
          لعملائنا. نحن نتفهم أن عملائنا هم العمود الفقري لأعمالنا ، ونذهب إلى
          أبعد الحدود لضمان رضاهم. يتوفر فريقنا من ممثلي رعاية العملاء الودودين
          والمطلعين على مدار الساعة للإجابة على أي أسئلة ومعالجة المخاوف وتقديم
          حلول لأية مشكلات قد تنشأ. نسعى جاهدين لخلق تجربة إيجابية وشخصية لكل
          عميل من عملائنا ، ونحن ملتزمون ببناء علاقات دائمة معهم. رضاك هو
          أولويتنا القصوى ، ونتطلع إلى خدمتك.
        </p>
        <div
          id="acceptconsent"
          style={{ marginTop: "50px", marginBottom: "50px" }}
        >
          <label>
            <input
              style={{
                width: "20px",
                height: "28px",
                float: "right",
                marginLeft: "20px",
              }}
              type="checkbox"
              checked={checked}
              onChange={handleChange}
            />
            <span
              style={{
                marginLeft: "20px",
                fontSize: "20px",
              }}
            >
              من خلال النقر على مربع الاختيار ، أوافق على{" "}
              <a
                href="https://qiwa.sa/ar/TC_Video"
                target="_blank"
                rel="noreferrer"
              >
                الشروط والأحكام
              </a>{" "}
              و{" "}
              <a
                href="https://qiwa.sa/ar/Service_Level_Agreement"
                target="_blank"
                rel="noreferrer"
              >
                اتفاقية مستوى الخدمة.
              </a>
            </span>
          </label>
          <br /> <br />
          <button
            style={{
              fontSize: "20px",
              color: "white",
              background: checked ? "#134359" : "dimgrey",
              borderRadius: "5px",
              width: "160px",
              cursor: checked ? "auto" : "not-allowed",
              marginLeft: "10px",
              marginRight: "10px",
              height: "60px",
            }}
            onClick={handleVideoClick}
            disabled={!checked}
          >
            ابدأ مكالمة فيديو
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default HomeAR;
