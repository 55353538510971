import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomeEN from './components/home-en';
import HomeAR from './components/home-ar';
import NotFoundPage from './components/NotFoundPage';

function App(){
  return (
    <Router>
          <div >
            <Routes>
              <Route exact path="/" element={<HomeEN />} />
              <Route exact path="/en/dsb" element={<HomeEN />} />
              <Route exact path="/ar/dsb" element={<HomeAR />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
    </Router>
  )
}
export default App