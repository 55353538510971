import React from "react";
import logo from "../assets/img/logo_color.svg";
import { getUsers } from "./Utils";

const HomeEN = () => {
  const [checked, setChecked] = React.useState(false);
  const dir = "ltr";
  const handleChange = () => {
    setChecked(!checked);
  };

  const handleVideoClick = () => {
    let users = getUsers();
    window.open(
      `https://teams.microsoft.com/l/call/0/0?users=${users}&withVideo=true`,
      "_blank"
    );
  };

  return (
    <div
      dir={dir}
      style={{
        fontFamily: "Frutiger,sans-serif",
        backgroundColor: "rgb(235, 243, 246)",
      }}
    >
      <nav
        class="navbar navbar-expand-md"
        style={{
          backgroundColor: "#efeff7",
          boxShadow: "0 0 30px 1.26px rgba(0,0,0,.26)",
          position: "fixed",
          width: "100%",
        }}
      >
        <div class="nav-item" style={{ marginLeft: "50px" }}>
          <div class="nav-link">
            <img
              src={logo}
              className="img-fluid me-4"
              alt="sign up"
              width="70"
              height="60"
            />
          </div>
        </div>
        <div style={{ display: "block", width: "inherit" }} id="navbarNav">
          <ul class="navbar-nav" style={{ fontSize: "20px", width: "inherit" }}>
            <li class="nav-item" style={{ width: "inherit" }}>
              <a
                style={{
                  color: "#134359",
                  float: "right",
                  marginRight: "40px",
                  marginLeft: "40px",
                }}
                class="nav-link"
                href="/ar/dsb"
              >
                <i class="fa-solid fa-globe"></i> عربي
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div
        style={{
          height: "350px",
          fontWeight: "400",
          backgroundColor: "#ebf3f6",
          paddingTop: "150px",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            fontWeight: "400",
            marginBottom: "30px",
          }}
        >
          Qiwa Customer Video Calling Service
        </h1>
      </div>
      <div
        class="container"
        style={{
          marginTop: "20px",
          paddingLeft: "30px",
          background: "white",
          paddingRight: "30px",
          paddingTop: "30px",
          border: "4px solid white",
          borderRadius: "5px",
        }}
      >
        <h1
          style={{
            fontWeight: "500",
          }}
        >
          Customer Care Service ( For Our Differently Abled People )
        </h1>
        <br></br>
        <br></br>
        <p
          style={{
            fontSize: "20px",
            lineHeight: "2",
            marginBottom: "30px",
          }}
        >
          At our company, we are dedicated to providing top-notch customer care
          service to our clients. We understand that our customers are the
          backbone of our business, and we go above and beyond to ensure their
          satisfaction. Our team of friendly and knowledgeable customer care
          representatives is available around the clock to answer any questions,
          address concerns, and provide solutions to any issues that may arise.
          We strive to create a positive and personalized experience for each of
          our customers, and we are committed to building lasting relationships
          with them. Your satisfaction is our top priority, and we look forward
          to serving you.
        </p>
        <div
          id="acceptconsent"
          style={{ marginTop: "50px", marginBottom: "50px" }}
        >
          <label>
            <input
              style={{
                width: "20px",
                height: "28px",
                float: "left",
              }}
              type="checkbox"
              checked={checked}
              onChange={handleChange}
            />
            <span
              style={{
                marginLeft: "20px",
                fontSize: "20px",
              }}
            >
              By Clicking the checkbox, I agree to the{" "}
              <a
                href="https://qiwa.sa/en/TC_Video"
                target="_blank"
                rel="noreferrer"
              >
                Terms And Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://qiwa.sa/en/Service_Level_Agreement"
                target="_blank"
                rel="noreferrer"
              >
                Service Level Agreement.
              </a>
            </span>
          </label>
          <br /> <br />
          <button
            style={{
              fontSize: "20px",
              color: "white",
              background: checked ? "#134359" : "dimgrey",
              borderRadius: "5px",
              width: "160px",
              cursor: checked ? "auto" : "not-allowed",
              marginLeft: "10px",
              marginRight: "10px",
              height: "60px",
            }}
            onClick={handleVideoClick}
            disabled={!checked}
          >
            Start Video Call
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default HomeEN;
